// Highlight color customization
::-moz-selection {
    background: $color-primary;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    text-shadow: none;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold !important;
}

.color-black {
    color: $primary-black !important;
}

body {
    font-feature-settings: 'lnum' 1;
}

[x-cloak] { display: none; }