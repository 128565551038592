
.hero {
    width: 100%;
    height: 565px;
    position: relative;
    margin-bottom: 140px;

    .hero-bg {
        background: url('../images/pattern.png');
        background-size: cover;
        width: 25%;
        height: 100%;
    }

    .box-info {
        width: 60%;
        position: absolute;
        top: -30px;
        left: 75px;
        z-index: 11;
    }

    .box-action {
        width: 65%;
        height: 360px;
        position: absolute;
        top: 42%;
        left: 180px;
        z-index: 10;
    }

    .gift-card-box {
        position: absolute;
        height: auto;
        width: 360px;
        z-index: 11;
        top: 14%;
        right: 5%;

        .gift-card {
            width: 100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .hero-image-1 {
        position: absolute;
        top: 40px;
        right: 70px;
        border: 1px solid #fff;
    }

    .hero-image-2 {
        position: absolute;
        bottom: -30px;
        right: 90px;
        border: 1px solid #fff;
    }
}

.gift-bg, .bb-bg {
    position: relative;
}

.btn-toolbar {
    a {
        font-size: 12px;
        width: 100%;
        padding-left: 35px;
    }
}

.bordered-section {
    border-left: 100px solid rgb(255, 255, 255);
}

@media (max-width: 767.98px) {
    .hero {
        height: auto;
        margin-bottom: 0;

        &.bg-grey-light {
            background-color: white!important;
        }

        .hero-image-2 {
            bottom: 18%;
            left: 10%;
            border: 1px solid #fff;
            width: 50%;
            z-index: 13;
        }

        .build-own-shop-box-info {
            width: auto!important;
            position: relative!important;
            left: 0!important;

            .build-own-shop-title-1 {
                font-size: 3.6rem !important;
            }

            .build-own-shop-title-2 {
                font-size: 1.75rem !important;
            }
        }

        .build-own-shop-box-action {
            width: auto;
            height: auto;
            position: relative;
            left: 0;
            top: -28%;
        }
    }

    .gift-bg {
        .btn {
            &.btn-outline-primary {
                width: 50%;
            }
        }
    }

    .bordered-section {
        border-left: none;

        .btn {
            &.btn-outline-primary {
                width: 70%;
            }
        }
    }
}

.floating-buttons-right {
    button {
        &:focus {
            outline: none !important;
        }
    }

    .btn-float {
        position: fixed;
        right: -125px;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        background: #d3d3d3;
        width: 220px;
        height: 80px;
        font-size: 0.6rem;
        border: none;
        transition: right 0.5s;
        z-index: 9999;
        opacity: 0.8;

        &.top {
            position: fixed;
            top: 280px;
            &.support {
                &:hover {
                    right: 0px;
                    width: 320px;
                }
            }
        }

        &.middle {
            position: fixed;
            top: 370px;
            &.support {
                &:hover {
                    right: 0;
                    width: 320px;
                }
            }
        }

        &.bottom {
            position: fixed;
            top: 460px;
            background-color: #f2eae7;
        }

        @media only screen and (max-width: 1180px) {
            display: none;
        }

        img {
            max-width: 40px;
            margin-bottom: 5px;
        }

        a {
            text-decoration: none;

            img {
                max-width: 40px;
                margin-bottom: 5px;
            }

            span {
                color: #000 !important;
            }
        }
    }
}

@supports (-moz-appearance: none) {
    .faq-element {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }
}
