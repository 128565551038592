@mixin antialiased() {
    -webkit-font-smoothing: antialiased;
}

@mixin headline() {
    font-family: "Archivo Black", serif;
}

@mixin text() {
    font-family: "Raleway", serif;
    font-feature-settings: 'lnum' 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 900;

    + ul {
        padding-top: 0.5rem !important;
    }
}

h5,
.h5 {
    margin-bottom: 0.3em;
}

.section-title,
.page-title,
.headline {
    @include headline();
    @include antialiased();
    text-transform: uppercase;
    line-height: 1.2em;
    font-size: 2.8rem;
}

.sub-headline {
    @include headline();
    @include antialiased();
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 400!important;
}

@media (max-width: 767.98px) {
    .section-title,
    .page-title,
    .headline {
        font-size: 1.5rem;
    }

    .sub-headline {
        @include antialiased();
        text-transform: uppercase;
        line-height: 1.2em;
        font-size: 1rem;
    }
}

ul {
    list-style: none;
    padding-inline-start: 0;
    padding-left: 0;
}

p {
    @include text();
}