.btn {
    font-size: 0.85rem;
}

.btn-outline-primary {
    @include button-outline-variant(lighten($color-primary, 80%), #000, lighten($color-primary, 70%), #fff);
    @include border-radius(0);
    color: #fff;
    font-weight: bold;
    font-family: "Archivo Black", serif;
}

.btn-outline-secondary {
    @include button-outline-variant($color-primary, #000, lighten($color-primary, 30%), $color-primary);
    @include border-radius(0);
    background-color: #eae3e3;
    border-color: #aa8c8a;
    color: #000;
    font-weight: bold;
    font-family: "Archivo Black", serif;
}


.btn-t-primary {
    color: $primary-white;
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
        color: $primary-white;
        background-color: darken($color-primary, 10%);
        border-color: darken($color-primary, 20%);
    }
}

.btn-t-default {
    color: #6c757d;
    background-color: #e5ebf1;
    border-color: #cfd5dc;

    &:hover {
        background-color: darken(#e5ebf1, 10%);
        border-color: darken(#e5ebf1, 20%);
    }
}

.btn:focus {
    box-shadow: none;
}
