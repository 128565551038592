$enable-rounded: false;

$color-primary: #aa8c8a;
$color-secondary:'';

$grey-blueish-bg: #f2f5f8;

// Font Variables
$raleway: 'Raleway', serif;

$font-family-secondary: $raleway;
$primary-black: #393535;
$primary-white: #fff;
$primary-grey: #ccc;

// social brand colors
$color-linkedin: #0077B5;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;

// Override bootstrap defaults
$font-family-base: $raleway;
$body-bg: $grey-blueish-bg;
$link-color: $color-primary;

$theme-colors: (
    primary: $color-primary,
);

// Body
$body-bg: #ffffff;

// Typography
$font-size-base: 12px; // this changes the font-size throughout bootstrap
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white : #fff;
$black : #0b0b0b;
$grey  : #797e83;

$colors-list: (
    black: (
        base: #000,
    ),
    white: (
        base: #fff,
    ),
    grey: (
        xx-light: lighten($grey, 43%),
        x-light: lighten($grey, 35%),
        light: #f4f4f4,
        soft: #eee8e8,
        base: $grey,
        dark: darken($grey, 8%),
        x-dark: darken($grey, 16%)
    ),
    yellow: (
        base: #ecaf2d
    ),
    green: (
        base: #5ad864
    ),
    red: (
        light: #f2eae7,
        base: #aa8c8a,
        dark: #9c676c,
    ),
);

$font-sizes: (
    x-sm: 0.5rem !important,
    sm: 0.8rem !important,
    md: 1rem !important,
    lg: 1.5rem !important,
    x-lg: 2.5rem !important,
    xx-lg: 3rem !important
);

$font-weight: (
    100: 100 !important,
    200: 200 !important,
    300: 300 !important,
    400: 400 !important,
    500: 500 !important,
    600: 600 !important,
    700: 700 !important,
    800: 800 !important,
    900: 900 !important
);

@mixin custom-modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
    @each $key, $value in $map {
        &#{if($key != $base, #{$prefix}#{$key}, '')} {
            @if type-of($value) == "map" {
                @include custom-modifiers($value, $attribute, $separator);
            } @else {
                #{$attribute}: $value;
            }
        }
    }
}

.text {
    // text-sm
    @include custom-modifiers($font-sizes, 'font-size', '-');
}

.font-weight {
    @include custom-modifiers($font-weight, 'font-weight', '-');
}

.color {
    // color-grey-light
    @include custom-modifiers($colors-list, 'color', '-');
}

.fill {
    @include custom-modifiers($colors-list, 'fill', '-');
}

.bg {
    // bg-grey-light
    @include custom-modifiers($colors-list, 'background-color', $prefix: '-');
}
