
.input-primary {
    color: #fff !important;
    border: 1px solid #fff !important;
    background: $color-primary !important;

    @include input-placeholder {
        color: #fff !important;
        font-family: 'Archivo Black', sans-serif;
    }
}

.input-big {
    height: 60px !important;
    font-size: .9em;
    font-family: 'Archivo Black', sans-serif;
}